import {
  Modal,
  Button,
  messages as sharedMessages,
} from '@tml-component-library/src';
import { Typography } from 'antd';
import { messages } from '../messages';

interface ExitAlertModalProps {
  open: boolean;
  onCancel: () => void;
  onExit: () => void;
  topicName?: string;
}

export const ExitAlertModal = ({
  open,
  onCancel,
  onExit,
  topicName,
}: ExitAlertModalProps) => {
  return (
    <Modal
      open={open}
      title={messages.leaveCourse}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <div>
        <Typography.Paragraph className="text-sm">
          {messages.dropProgressToTopic(topicName)}
        </Typography.Paragraph>
        <div className="flex justify-end gap-2">
          <Button onClick={onCancel}>{sharedMessages.cancel}</Button>
          <Button
            type="primary"
            onClick={onExit}
          >
            {sharedMessages.exit}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
