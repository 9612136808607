import { Dispatch, SetStateAction } from 'react';
import {
  ActionEntity,
  ChainEntity,
  ComponentTypeEntity,
  ContentEntity,
  DialogEntity,
  DialogRoles,
  QuestionTypeEntity,
  QuizEntity,
  QuizQuestionStatuses,
} from 'api/learning-steps/types';
import { ILEInput } from 'components/ILEInput';
import {
  CompleteCoursePreview,
  ComponentPreview,
  ImageView,
} from '../components';
import { ILEQuestion, MultipleQuestion, OpenQuestion } from '../questions';
import {
  ILE_DIALOG_ROLES,
  OPEN_DIALOG_ROLES,
  filterDialogDataByRole,
} from './filterDialogDataByRole';

interface LearningProcessComponentsProps {
  dialog: DialogEntity[];
  answer: string;
  onAnswer: Dispatch<SetStateAction<string>>;
  onAnimationDone: Dispatch<SetStateAction<boolean>>;
  setIsDisabledNextStep: Dispatch<SetStateAction<boolean>>;
  isProcessing: boolean;
  content?: ContentEntity;
  quiz?: QuizEntity;
  currentChain?: ChainEntity;
  isAnimationDone?: boolean;
  nextChain?: ChainEntity;
}

export const getLearningProcessComponents = ({
  dialog,
  answer,
  onAnswer,
  onAnimationDone,
  isProcessing,
  content,
  quiz,
  currentChain,
  isAnimationDone,
  nextChain,
  setIsDisabledNextStep,
}: LearningProcessComponentsProps) => {
  const { componentType: type, id } = currentChain || {};
  const isTransitionToNextStepEnabled =
    !isProcessing &&
    isAnimationDone &&
    (quiz?.quizQuestionStatus === QuizQuestionStatuses.FINISHED ||
      nextChain?.availableCommandType === ActionEntity.CREATE_QUIZ ||
      currentChain?.componentType === ComponentTypeEntity.COMPLETE_COURSE);

  switch (type) {
    case ComponentTypeEntity.MODULE:
    case ComponentTypeEntity.TOPIC:
      return {
        leftContentComponent: (
          <ComponentPreview
            key={id}
            description={content?.introduction || content?.description}
            onAnimationDone={onAnimationDone}
            showTransitionNotification={isTransitionToNextStepEnabled}
          />
        ),
        rightContentComponent: (
          <ImageView
            key={id}
            path={content?.imageUrl}
          />
        ),
      };
    case ComponentTypeEntity.LESSON:
      return {
        leftContentComponent: (
          <ComponentPreview
            key={id}
            description={content?.textContent}
            onAnimationDone={onAnimationDone}
            showTransitionNotification={isTransitionToNextStepEnabled}
          />
        ),
        rightContentComponent: (
          <ImageView
            key={id}
            path={content?.imageUrl}
          />
        ),
      };
    case ComponentTypeEntity.MODULE_QUIZ:
    case ComponentTypeEntity.TOPIC_QUIZ:
    case ComponentTypeEntity.LESSON_QUIZ: {
      const {
        questionId,
        questionType,
        questionText,
        options,
        correctAnswer,
        quizQuestionStatus,
        feedback,
      } = quiz || {};

      switch (questionType) {
        case QuestionTypeEntity.ILE_ESSAY:
          return {
            leftContentComponent: (
              <ILEQuestion
                key={questionId}
                description={questionText}
                messages={filterDialogDataByRole(dialog, ILE_DIALOG_ROLES)}
                onAnimationDone={onAnimationDone}
                showTransitionNotification={isTransitionToNextStepEnabled}
              />
            ),
            rightContentComponent: (
              <ILEInput
                key={questionId}
                answer={answer}
                onAnswer={onAnswer}
                messages={filterDialogDataByRole(dialog, [DialogRoles.USER])}
                disabled={
                  quizQuestionStatus === QuizQuestionStatuses.FINISHED ||
                  isProcessing
                }
                isTransitionToNextStepEnabled={Boolean(
                  isTransitionToNextStepEnabled
                )}
                setIsDisabledNextStep={setIsDisabledNextStep}
              />
            ),
          };

        case QuestionTypeEntity.OPEN:
          return {
            leftContentComponent: (
              <OpenQuestion
                key={questionId}
                description={questionText}
                answer={answer}
                messages={filterDialogDataByRole(dialog, OPEN_DIALOG_ROLES)}
                onAnswer={onAnswer}
                onAnimationDone={onAnimationDone}
                showAnswerInput={
                  quizQuestionStatus !== QuizQuestionStatuses.FINISHED &&
                  Boolean(isAnimationDone) &&
                  !isProcessing
                }
                showTransitionNotification={isTransitionToNextStepEnabled}
              />
            ),
            rightContentComponent: (
              <ImageView
                key={questionId}
                path={content?.imageUrl}
              />
            ),
          };
        case QuestionTypeEntity.MULTIPLE:
          return {
            leftContentComponent: (
              <MultipleQuestion
                key={questionId}
                showResults={
                  quizQuestionStatus === QuizQuestionStatuses.FINISHED
                }
                feedback={feedback}
                correctValues={correctAnswer?.split(',')}
                text={questionText}
                options={options}
                onAnswer={onAnswer}
                onAnimationDone={onAnimationDone}
                disabled={isProcessing}
                showTransitionNotification={isTransitionToNextStepEnabled}
              />
            ),
            rightContentComponent: (
              <ImageView
                key={questionId}
                path={content?.imageUrl}
              />
            ),
          };
        default:
          return {};
      }
    }
    case ComponentTypeEntity.COMPLETE_COURSE:
      return {
        leftContentComponent: (
          <CompleteCoursePreview
            key={id}
            description={content?.description}
            modules={content?.completedModules}
            onAnimationDone={onAnimationDone}
            showTransitionNotification={isTransitionToNextStepEnabled}
          />
        ),
        rightContentComponent: (
          <ImageView
            key={id}
            path={content?.imageUrl}
          />
        ),
      };

    default:
      return {};
  }
};
