export enum QuestionTypeEntity {
  ILE_ESSAY = 'ILE_ESSAY',
  OPEN = 'OPEN',
  MULTIPLE = 'MULTIPLE',
}

export enum ComponentTypeEntity {
  MODULE = 'MODULE',
  TOPIC = 'TOPIC',
  LESSON = 'LESSON',
  MODULE_QUIZ = 'MODULE_QUIZ',
  TOPIC_QUIZ = 'TOPIC_QUIZ',
  LESSON_QUIZ = 'LESSON_QUIZ',
  COMPLETE_COURSE = 'COMPLETE_COURSE',
}

export enum ActionEntity {
  NO_COMMAND = 'NO_COMMAND',
  PREV = 'PREV',
  NEXT = 'NEXT',
  NEXT_QUESTION = 'NEXT_QUESTION',
  CHECK_ANSWER = 'CHECK_ANSWER',
  CREATE_QUIZ = 'CREATE_QUIZ',
}

export enum DialogRoles {
  SYSTEM = 'system',
  USER = 'user',
  ASSISTANT = 'assistant',
}

export enum AnswerStatuses {
  INCORRECT = 'INCORRECT',
  CORRECT = 'CORRECT',
}

export enum QuizQuestionStatuses {
  FINISHED = 'FINISHED',
  NOT_FINISHED = 'NOT_FINISHED',
}

export interface HeaderTitleEntity {
  module: string;
  topic: string;
  lesson: string;
}

export interface ContentEntity {
  headerTitle: HeaderTitleEntity;
  name: string;
  description: string;
  introduction: string; // TODO: move to the BE side. Should be in description field
  textContent: string;
  imageUrl: string;
  completedModules: string[];
}

export interface OptionsEntity {
  [key: string]: string;
}

export interface DialogEntity {
  role: DialogRoles;
  content: string;
}

export interface QuizEntity {
  id: string;
  questionId: string;
  questionType: QuestionTypeEntity;
  questionText: string;
  options: OptionsEntity;
  dialog: DialogEntity[];
  correctAnswer: string;
  answerStatus: AnswerStatuses;
  feedback: string;
  quizQuestionStatus: QuizQuestionStatuses;
}

export interface ChainEntity {
  id: string;
  componentId: string;
  componentType: ComponentTypeEntity;
  availableCommandType: ActionEntity;
  ordinalNumber: number;
}

export interface LearningStepsResponse {
  previousLearningChain: ChainEntity;
  currentLearningChain: ChainEntity;
  nextLearningChain: ChainEntity;
  content: ContentEntity;
  quiz: QuizEntity;
  totalChains: number;
}

export interface LearningStepsRequest {
  knowledgeBaseId: string;
}

export interface LearningStepRequest {
  knowledgeBaseId: string;
  id: string;
  commandType: ActionEntity;
  quiz?: {
    id: string;
    questionId: string;
    answer?: string;
    dialog?: DialogEntity[];
  };
}
