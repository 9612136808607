export const calculateInputCoords = (input: DOMRect, parent: DOMRect) => {
  const { height } = input;
  const width = parent.width - input.width;
  const imageGap = input.width / 6;

  return {
    left: 0,
    top: 0,
    width: width + imageGap,
    height: height * 0.9,
    imageGap,
  };
};
