import {
  ComponentTypeEntity,
  HeaderTitleEntity,
} from 'api/learning-steps/types';
import { messages } from '../messages';

export const getHeaderTitle = (
  titles?: HeaderTitleEntity,
  component?: ComponentTypeEntity
) => {
  switch (component) {
    case ComponentTypeEntity.MODULE:
    case ComponentTypeEntity.MODULE_QUIZ:
      return titles?.module;
    case ComponentTypeEntity.TOPIC:
    case ComponentTypeEntity.TOPIC_QUIZ:
      return `${titles?.module} > ${titles?.topic}`;
    case ComponentTypeEntity.LESSON:
    case ComponentTypeEntity.LESSON_QUIZ:
      return titles?.lesson;
    case ComponentTypeEntity.COMPLETE_COURSE:
      return messages.courseCompleted;
    default:
      return messages.continue;
  }
};
