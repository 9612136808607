export const UPLOAD_FILE_REQUEST_CONFIG = {
  headers: (fileType: string) => ({
    'Access-Control-Allow-Origin': '*',
    'x-ms-blob-type': 'BlockBlob',
    'Content-Type': fileType,
    'x-ms-blob-content-type': fileType,
  }),
};

export const CV_FORMATS = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'application/pdf',
];

export const REQUEST_LIST_SIZE = 1000000;

export const DEFAULT_LIST_PARAMS = {
  page: 0,
  size: REQUEST_LIST_SIZE,
};

export const START_ANIMATION_DELAY = 300;

export const SCROLL_ANIMATION_DELAY = START_ANIMATION_DELAY;

/**
 * Symbols Limitation for websocket send request - ~3000-4000 words
 */
export const WEBSOCKET_ANSWER_MAX_LENGTH = 15000;
