import { SignIn } from 'features/sign-in';
import { Meta } from '@tml-component-library/src/components/Meta';

export const SignInPage = () => (
  <>
    <Meta title="Log In" />

    <SignIn />
  </>
);
