import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { once } from 'lodash';
import { useExchangeTokenMutation, useSignInMutation } from 'api/auth/auth.api';
import { useActivateUserMutation } from 'api/user/user.api';
import { SignInRequest } from 'api/auth/types';
import { authRepository } from './auth.repository';

interface AuthProviderProps {
  children: ReactNode;
}

type Callback = (status: ExchangeTokenStatuses) => void;

interface AuthContextType {
  isLoading: boolean;
  isAuthorized: boolean;
  signIn: (data: SignInRequest) => Promise<void>;
  exchangeToken: (
    token: string,
    callback: Callback,
    isActivated: boolean
  ) => void;
  signOut: () => void;
}

export enum ExchangeTokenStatuses {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  TOKEN_NOT_EXIST = 'TOKEN_NOT_EXIST',
}

export const AuthContext = createContext<AuthContextType>({
  isAuthorized: false,
} as AuthContextType);

const defaultIsHasSession = authRepository.isHasSession();

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [signInQuery, { isLoading: signInLoading }] = useSignInMutation();
  const [exchangeTokenQuery, { isLoading: exchangeTokenLoading }] =
    useExchangeTokenMutation();
  const [activateUserQuery, { isLoading: activateUserLoading }] =
    useActivateUserMutation();

  const [isAuthorized, setIsAuthorized] = useState(defaultIsHasSession);

  const signIn = useCallback(
    (body: SignInRequest) => signInQuery(body).unwrap(),
    [signInQuery]
  );

  const signOut = useCallback(() => {
    authRepository.clear();
    setIsAuthorized(false);
  }, []);

  const exchangeToken = useCallback(
    once(
      async (
        oneTimeToken: string,
        callback: Callback,
        isActivated: boolean
      ) => {
        authRepository.setAuthTokens({
          accessToken: oneTimeToken,
          refreshToken: '',
        });

        try {
          let tokens;

          if (isActivated) {
            tokens = await exchangeTokenQuery().unwrap();
          } else {
            tokens = await activateUserQuery().unwrap();
          }

          authRepository.setHasSession(true);
          setIsAuthorized(true);
          authRepository.setAuthTokens(tokens);
          callback(ExchangeTokenStatuses.SUCCESS);
        } catch (error) {
          authRepository.clear();
          setIsAuthorized(false);
          callback(ExchangeTokenStatuses.ERROR);
        }
      }
    ),
    [exchangeTokenQuery, activateUserQuery]
  );

  const context = useMemo(
    () => ({
      signIn,
      signOut,
      isLoading: exchangeTokenLoading || signInLoading || activateUserLoading,
      exchangeToken,
      isAuthorized,
    }),
    [
      signIn,
      signOut,
      exchangeToken,
      exchangeTokenLoading,
      signInLoading,
      isAuthorized,
    ]
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
