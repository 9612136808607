export const Routes = {
  root: () => '/',
  signIn: () => '/sign-in',
  signInSuccess: () => '/sign-in-success',
  signInError: () => '/sign-in-error',

  courses: () => '/courses',
  settings: () => '/settings',
  course: (kbId = ':kbId') => `/courses/${kbId}`,
};
