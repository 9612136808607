const SEPARATOR = ' ';

export const rowSplitter = (row: string) => {
  const words = row.split(SEPARATOR);

  /*
   * Exclude redundant space at the end of the row for fixing cases when the next row started with comma or dot
   */
  const lastRowElementIndex = words.length - 1;

  return words.map(
    (element, i) => `${element}${lastRowElementIndex === i ? '' : SEPARATOR}`
  );
};
