import { createBrowserRouter, Navigate } from 'react-router-dom';
import { LoginLayout } from '@tml-component-library/src/layouts';
import { SignInPage } from 'pages/sign-in/SignInPage';
import { SignInSuccessPage } from 'pages/sign-in-success/SignInSuccessPage';
import { SignInErrorPage } from 'pages/sign-in-error/SignInErrorPage';
import { HomePage } from 'pages/home/HomePage';
import { SettingsPage } from 'pages/settings/SettingsPage';
import { LearningProcessPage } from 'pages/learning-process/LearningProcessPage';
import { CoursesPage } from 'pages/courses/CoursesPage';
import { UserAuthoritiesEntity } from 'api/user/types';
import { PrivateRoute } from './components/PrivateRoute';
import { PublicRoute } from './components/PublicRoute';
import { Routes } from './routes';
import { ProtectedRoute } from './components/ProtectedRoute';

export const router = createBrowserRouter([
  {
    element: <PublicRoute />,
    children: [
      {
        element: <LoginLayout />,
        children: [
          {
            path: Routes.signIn(),
            element: <SignInPage />,
          },
          {
            path: Routes.signInSuccess(),
            element: <SignInSuccessPage />,
          },
          {
            path: Routes.signInError(),
            element: <SignInErrorPage />,
          },
        ],
      },
    ],
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        element: (
          <ProtectedRoute userRole={UserAuthoritiesEntity.ROLE_STUDENT} />
        ),
        children: [
          {
            path: Routes.root(),
            element: <HomePage />,
            children: [
              {
                path: Routes.root(),
                element: (
                  <Navigate
                    to={Routes.courses()}
                    replace
                  />
                ),
              },
              {
                path: Routes.courses(),
                element: <CoursesPage />,
              },
              {
                path: Routes.settings(),
                element: <SettingsPage />,
              },
            ],
          },
          {
            path: Routes.course(),
            element: <LearningProcessPage />,
          },
        ],
      },
    ],
  },
]);
