import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from '@tml-component-library/src';
import {
  useExitLearningMutation,
  useGetLearningStepQuery,
  useSendLearningStepMutation,
} from 'api/learning-steps/learning-steps.api';
import { Routes } from 'router/routes';
import {
  ActionEntity,
  ComponentTypeEntity,
  DialogEntity,
  DialogRoles,
  QuestionTypeEntity,
} from 'api/learning-steps/types';
import { SmallScreenSizeWarningModal } from 'components/SmallScreenSizeWarningModal';
import { LearningProcessLayout } from './layouts/LearningProcessLayout';
import { getLearningProcessComponents, getHeaderTitle } from './utils';
import { ExitAlertModal } from './components';
import { calculateProgress } from './utils/calculateProgress';

export const LearningProcess = () => {
  const navigate = useNavigate();
  const { kbId } = useParams();

  const {
    data: {
      content,
      quiz,
      currentLearningChain,
      nextLearningChain,
      totalChains,
    } = {},
  } = useGetLearningStepQuery({
    knowledgeBaseId: kbId!,
  });
  const [sendLearningStep, { isLoading: isSendLearningStepLoading }] =
    useSendLearningStepMutation();
  const [exitLearning] = useExitLearningMutation();

  const {
    availableCommandType: currentAction,
    id: currentId,
    componentType,
  } = currentLearningChain || {};

  const {
    showModal: showExitAlertModal,
    hideModal: hideExitAlertModal,
    open: openExitAlertModal,
  } = useModal();

  const [isProcessing, setIsProcessing] = useState(false);
  const [isAnimationDone, setIsAnimationDone] = useState(false);
  const [answer, setAnswer] = useState('');
  const [isDisabledNextStep, setIsDisabledNextStep] = useState(false);
  const [dialog, setDialog] = useState<DialogEntity[]>([]);

  const { leftContentComponent, rightContentComponent } =
    getLearningProcessComponents({
      dialog,
      answer,
      onAnswer: setAnswer,
      onAnimationDone: setIsAnimationDone,
      isProcessing,
      content,
      quiz,
      currentChain: currentLearningChain,
      isAnimationDone,
      nextChain: nextLearningChain,
      setIsDisabledNextStep,
    });

  useEffect(() => {
    const messages = quiz?.dialog;

    if (messages) {
      setDialog(messages);
    }
  }, [quiz?.dialog]);

  useEffect(() => {
    setIsProcessing(false);
  }, [nextLearningChain, currentLearningChain]);

  const handleClearState = () => {
    setAnswer('');
    setDialog([]);
  };

  const handleExit = () => {
    exitLearning();
    navigate(Routes.courses());
  };

  const handleNext = async () => {
    setIsAnimationDone(false);
    setIsProcessing(true);

    if (componentType === ComponentTypeEntity.COMPLETE_COURSE) {
      handleExit();
      handleClearState();

      return;
    }

    if (currentAction === ActionEntity.CHECK_ANSWER) {
      await sendLearningStep({
        knowledgeBaseId: kbId!,
        id: currentId!,
        commandType: currentAction,
        quiz: {
          id: quiz?.id!,
          questionId: quiz?.questionId!,
          ...(answer ? { answer } : {}),
        },
      }).unwrap();

      setDialog((messages) => [
        ...messages,
        { content: answer, role: DialogRoles.USER },
      ]);

      if (quiz?.questionType === QuestionTypeEntity.OPEN) {
        setAnswer('');
      }

      return;
    }

    if (currentAction === ActionEntity.NEXT_QUESTION) {
      await sendLearningStep({
        knowledgeBaseId: kbId!,
        id: currentId!,
        commandType: currentAction!,
        quiz: {
          id: quiz?.id!,
          questionId: quiz?.questionId!,
        },
      }).unwrap();

      handleClearState();
      return;
    }

    await sendLearningStep({
      knowledgeBaseId: kbId!,
      id: nextLearningChain?.id!,
      commandType: nextLearningChain?.availableCommandType!,
    }).unwrap();

    handleClearState();
  };

  const isQuestionAnswered =
    currentAction === ActionEntity.CHECK_ANSWER && !answer;
  const isNextDisabled =
    !isAnimationDone ||
    isQuestionAnswered ||
    isProcessing ||
    isSendLearningStepLoading ||
    isDisabledNextStep;

  return (
    <>
      <LearningProcessLayout
        isBiggerRightContent={
          quiz?.questionType === QuestionTypeEntity.ILE_ESSAY
        }
        title={getHeaderTitle(
          content?.headerTitle,
          currentLearningChain?.componentType
        )}
        leftContent={leftContentComponent}
        rightContent={rightContentComponent}
        onExit={showExitAlertModal}
        onNext={handleNext}
        isNextDisabled={isNextDisabled}
        isProcessing={isProcessing || isSendLearningStepLoading}
        progress={calculateProgress(
          totalChains!,
          currentLearningChain?.ordinalNumber
        )}
      />

      <SmallScreenSizeWarningModal />

      <ExitAlertModal
        open={openExitAlertModal}
        onExit={handleExit}
        onCancel={hideExitAlertModal}
        topicName={content?.headerTitle?.topic}
      />
    </>
  );
};
