declare global {
  interface Window {
    setWordDelayTyping: (speed?: number) => void;
    setCopyPasteEnabled: (disabled?: boolean) => void;
  }
}

export class LearningConfigStore {
  public _defaultWordDelayTyping = 100;

  public _copyPasteEnabled = true;

  get defaultWordDelayTyping(): number {
    return this._defaultWordDelayTyping;
  }

  set defaultWordDelayTyping(speed: number) {
    this._defaultWordDelayTyping = speed;
  }

  get copyPasteEnabled() {
    return this._copyPasteEnabled;
  }

  set copyPasteEnabled(enabled: boolean) {
    this._copyPasteEnabled = enabled;
  }

  constructor() {
    window.setWordDelayTyping = (speed?: number) => {
      if (typeof speed === 'number' && speed > 0) {
        this.defaultWordDelayTyping = speed;
        return;
      }

      throw new Error(
        'Please send the correct argument (should be number) - typing delay per word started from 1ms'
      );
    };

    window.setCopyPasteEnabled = (disabled?: boolean) => {
      if (typeof disabled === 'boolean') {
        this.copyPasteEnabled = disabled;
        return;
      }

      throw new Error(
        'Please send the correct argument (should be true or false)'
      );
    };
  }
}

export const LearningConfig = new LearningConfigStore();
