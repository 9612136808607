import { Typography } from 'antd';
import { Modal } from '@tml-component-library/src';
import { increaseScreenSizeImage } from '@tml-component-library/src/icons';
import { useScreenSizeLimit } from 'hooks';
import { messages } from './messages';

const MAX_MODAL_WIDTH = 650;

export const SmallScreenSizeWarningModal = () => {
  const { isLess } = useScreenSizeLimit();

  return (
    <Modal
      open={isLess}
      width={MAX_MODAL_WIDTH}
      centered
      title={
        <Typography.Title
          className="pt-4"
          level={3}
        >
          {messages.browserWindowToSmall}
        </Typography.Title>
      }
      classNames={{
        header: 'w-full text-center',
        content: 'w-full text-center',
      }}
      closable={false}
      footer={null}
      destroyOnClose
    >
      <Typography.Paragraph className="text-xl">
        {messages.makeWindowLarger}
      </Typography.Paragraph>
      <img
        className="pt-14 pb-16"
        src={increaseScreenSizeImage}
        alt={messages.makeWindowLarger}
      />
    </Modal>
  );
};
