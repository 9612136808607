import { DialogEntity, DialogRoles } from 'api/learning-steps/types';

export const OPEN_DIALOG_ROLES = [DialogRoles.USER, DialogRoles.ASSISTANT];

export const ILE_DIALOG_ROLES = [DialogRoles.ASSISTANT];

export const filterDialogDataByRole = (
  messages: DialogEntity[],
  roles: DialogRoles[]
) => messages?.filter(({ role }) => roles.includes(role));
