import {
  Navigate,
  Outlet,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useAuth } from 'common/auth';
import { Spinner } from '@tml-component-library/src/components/Spinner';
import { UserProvider } from 'common/user/UserProvider';
import { handleNavigateByStatus } from './utils';
import {
  SECURITY_TOKEN_SEARCH_KEY,
  SHOULD_ACTIVATE_SEARCH_KEY,
} from './constants';
import { Routes } from '../routes';

export const PrivateRoute = () => {
  const { exchangeToken, isAuthorized, isLoading } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const securityToken = searchParams.get(SECURITY_TOKEN_SEARCH_KEY);
  const isActivated = !searchParams.get(SHOULD_ACTIVATE_SEARCH_KEY);

  if (isLoading) return <Spinner />;

  if (securityToken) {
    exchangeToken(securityToken, handleNavigateByStatus(navigate), isActivated);

    return <Spinner />;
  }

  if (!isAuthorized) {
    return (
      <Navigate
        to={Routes.signIn()}
        replace
      />
    );
  }

  return (
    <UserProvider>
      <Outlet />
    </UserProvider>
  );
};
