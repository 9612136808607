export const messages = {
  loginLinkSent: 'Secure Login Link Sent',
  sentToEmail: 'We sent an email to ',
  lookMailbox:
    'Please look into your mailbox and make sure to check your spam folder.',
  waitFewMinutes:
    'Please wait a few minutes before trying again. Generating a new link will deactivate the previous link.',
  newSecureLinkSent: 'New Secure Link sent.',
  resendLink: 'Resend Secure Login Link',
};
