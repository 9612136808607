import { AnswerStatuses } from 'api/learning-steps/types';
import { messages } from '../messages';

export const CorrectAnswerMessages = [
  messages.outstanding,
  messages.perfect,
  messages.congratulations,
  messages.amazing,
];

export const IncorrectAnswerMessages = [
  messages.mistake,
  messages.discouraged,
  messages.improve,
  messages.keep,
];

export const AnswerMessages = {
  [AnswerStatuses.CORRECT]: CorrectAnswerMessages,
  [AnswerStatuses.INCORRECT]: IncorrectAnswerMessages,
};

export const getRandomNumberFromInterval = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const getRandomErrorMessage = (status: AnswerStatuses) => {
  const relatedMessages = AnswerMessages[status];
  const messageIndex = getRandomNumberFromInterval(0, 3);

  return relatedMessages[messageIndex];
};
