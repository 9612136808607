import { useCallback, useMemo } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { RequiredMark } from 'antd/es/form/Form';
import {
  CustomFormInputLabel,
  CustomFormProvider,
} from '@tml-component-library/src';
import { useUpdateUserMutation, useUserSelfQuery } from 'api/user/user.api';
import { useCreateFileMutation } from 'api/file/file.api';
import { FileType } from 'api/file/types';
import { useUploadFileByUrl } from 'hooks';
import { SettingsFormFields } from './constants';
import { SettingsFormValues } from './types';
import { settingsValidationSchema } from './validation.schema';
import { SettingsForm } from './components/SettingsForm';

const FormMark: RequiredMark = (label, { required }) => (
  <CustomFormInputLabel
    required={required}
    content={label}
    labelStyles={{
      fontSize: 14,
    }}
  />
);

export const Settings = () => {
  const { data } = useUserSelfQuery();
  const [createFile] = useCreateFileMutation();
  const [updateUser] = useUpdateUserMutation();
  const { uploadFileByUrl } = useUploadFileByUrl();

  const initialValues = useMemo(
    () => ({
      [SettingsFormFields.RESUME]: data?.cvFile.name,
    }),
    [data]
  );

  const onSubmit = useCallback(
    async (values: SettingsFormValues) => {
      const generatedUUID = uuidV4();
      const wasCvValueChanged =
        typeof values[SettingsFormFields.RESUME] !== 'string';
      const cvValue = values[SettingsFormFields.RESUME];

      if (wasCvValueChanged) {
        await uploadFileByUrl(generatedUUID, cvValue as File);

        await createFile({
          files: [
            {
              id: generatedUUID,
              name: (cvValue as File).name,
              nameWithExtension: (cvValue as File).name,
              type: FileType.USER_CV,
            },
          ],
        });
      }

      if (data) {
        const requestBody = {
          ...data,
          cvFile: {
            id: wasCvValueChanged ? generatedUUID : data?.cvFile.id,
          },
        };

        await updateUser(requestBody);
      }
    },
    [createFile, data, updateUser, uploadFileByUrl]
  );

  return (
    <div className="p-6 h-full">
      <CustomFormProvider
        initialValues={initialValues}
        onSubmit={onSubmit}
        layout="vertical"
        requiredMark={FormMark}
        validationSchema={settingsValidationSchema}
      >
        <SettingsForm
          defaultValues={{
            [SettingsFormFields.RESUME]: data?.cvFile?.name,
          }}
        />
      </CustomFormProvider>
    </div>
  );
};
