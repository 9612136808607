import { createApi } from '@reduxjs/toolkit/query/react';
import { EventType } from '@tml-component-library/src';
import { StompWebSocket, axiosQuery } from 'utils';
import {
  LearningStepRequest,
  LearningStepsRequest,
  LearningStepsResponse,
} from './types';

const WebSocket = new StompWebSocket();

const infiniteCache = 1000000;

export const learningStepsApi = createApi({
  baseQuery: axiosQuery({ baseUrl: '/learning' }),
  tagTypes: ['learningSteps'],
  endpoints: (build) => ({
    getLearningStep: build.query<LearningStepsResponse, LearningStepsRequest>({
      keepUnusedDataFor: infiniteCache,
      query: (params) => ({
        url: `/current-step`,
        params,
      }),
      providesTags: ['learningSteps'],
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheEntryRemoved, cacheDataLoaded }
      ) {
        const handleAddSubscriptions = () => {
          const messageListener = (data: LearningStepsResponse) => {
            updateCachedData((draft) => {
              draft = data;
              return draft;
            });
          };

          WebSocket.subscribe('/user/queue/reply', messageListener);
        };

        await WebSocket.create();
        await WebSocket.connect(handleAddSubscriptions);

        if (WebSocket.isError) {
          const event = new CustomEvent(EventType.REQUEST_ERROR);
          document.dispatchEvent(event);

          return;
        }

        // Wait for the initial query to resolve before proceeding
        await cacheDataLoaded;

        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        await WebSocket.disconnect();
      },
    }),
    sendLearningStep: build.mutation<null, LearningStepRequest>({
      queryFn: () => ({ data: null }),
      async onQueryStarted({ knowledgeBaseId, ...data }, { dispatch }) {
        const handleAddSubscriptions = () => {
          const messageListener = (cache: LearningStepsResponse) => {
            dispatch(
              learningStepsApi.util.updateQueryData(
                'getLearningStep',
                { knowledgeBaseId },
                (draft) => {
                  draft = cache;
                  return draft;
                }
              )
            );
          };

          WebSocket.subscribe('/user/queue/reply', messageListener);
        };

        await WebSocket.create();
        await WebSocket.connect(handleAddSubscriptions);

        if (WebSocket.isError) {
          const event = new CustomEvent(EventType.REQUEST_ERROR);
          document.dispatchEvent(event);
          return;
        }

        WebSocket.publish('/app/message', data);
      },
    }),
    exitLearning: build.mutation<null, void>({
      queryFn: () => ({ data: null }),
      invalidatesTags: ['learningSteps'],
      async onQueryStarted() {
        await WebSocket.disconnect();
      },
    }),
  }),
});

export const {
  useGetLearningStepQuery,
  useSendLearningStepMutation,
  useExitLearningMutation,
} = learningStepsApi;
