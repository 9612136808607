import * as yup from 'yup';
import {
  ACCEPTED_CV_FORMATS,
  validationMessages,
} from '@tml-component-library/src';
import { SettingsFormFields } from './constants';

export const settingsValidationSchema = yup.object().shape({
  [SettingsFormFields.RESUME]: yup
    .mixed()
    .required(validationMessages.requiredField)
    .test({
      message: validationMessages.allowedOnlyPDF,
      test: (value) => {
        if (value instanceof File) {
          const file = value as File;
          return ACCEPTED_CV_FORMATS.includes(file.type);
        }

        return true;
      },
    }),
});
