import { useLayoutEffect, useRef, useState } from 'react';

interface Coordinates {
  left: number;
  top: number;
  width: number;
  height: number;
  imageGap: number;
}

type Callback = (input: DOMRect, parent: DOMRect) => Coordinates;

export const useResizeBasedParentObserver = (callback: Callback) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const [coordinates, setCoordinates] = useState<Coordinates>();

  useLayoutEffect(() => {
    const current = imageRef?.current;
    const parent = parentRef?.current;

    if (!current || !parent) return;

    const listener = () => {
      setCoordinates(
        callback(
          current.getBoundingClientRect(),
          parent.getBoundingClientRect()
        )
      );
    };

    /*
     * For listening changes for current element - case when parent and current independent
     */
    const currentObserver = new ResizeObserver(listener);
    currentObserver.observe(current);

    /*
     * For listening changes for parent element - case when current has maximum size
     */
    const parentObserver = new ResizeObserver(listener);
    parentObserver.observe(parent);

    return () => {
      currentObserver.unobserve(current);
      parentObserver.unobserve(parent);
    };
  }, [imageRef?.current, parentRef?.current]);

  return { coordinates, imageRef, parentRef };
};
