import { Input, Typography } from 'antd';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { OpenChatList } from 'components/ChatList';
import { ScrolledItemWrapper } from 'components/ScrolledItemWrapper';
import { MarkdownTypeAnimation } from 'components/MardownTypeAnimation';
import { DialogEntity } from 'api/learning-steps/types';
import { WEBSOCKET_ANSWER_MAX_LENGTH } from 'shared';
import { LearningConfig } from 'configs';
import { ContentLayout } from '../layouts/ContentLayout';

interface OpenQuestionProps {
  answer: string;
  messages: DialogEntity[];
  showAnswerInput: boolean;
  onAnswer: Dispatch<SetStateAction<string>>;
  onAnimationDone: Dispatch<SetStateAction<boolean>>;
  description?: string;
  showTransitionNotification?: boolean;
}

export const OpenQuestion = ({
  answer,
  description,
  messages,
  onAnimationDone,
  onAnswer,
  showAnswerInput,
  showTransitionNotification = false,
}: OpenQuestionProps) => {
  const [isTypingDone, setIsTypingDone] = useState(false);
  const [isChatTypingDone, setIsChatTypingDone] = useState(true);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onAnswer(e.target.value);
  };

  const handleChatTyping = (typing: boolean) => {
    setIsChatTypingDone(typing);
    onAnimationDone(typing);
  };

  const showInput = showAnswerInput && isTypingDone && isChatTypingDone;

  return (
    <ContentLayout
      isCentralized
      contentClassName="flex flex-col gap-4"
      showTransitionNotification={showTransitionNotification}
    >
      {description && (
        <Typography.Paragraph className="text-sm laptop:text-base desktop:text-xl font-gelasio">
          <MarkdownTypeAnimation
            markdown={description}
            onTypingDone={() => {
              setIsTypingDone(true);
              onAnimationDone(true);
            }}
          />
        </Typography.Paragraph>
      )}
      {isTypingDone && (
        <OpenChatList
          onTypingDone={handleChatTyping}
          messages={messages}
        />
      )}
      {showInput && (
        <ScrolledItemWrapper
          shouldBeScrolled
          className="py-3 bg-white"
        >
          <Input.TextArea
            className="text-sm laptop:text-base desktop:text-xl animate-fade animate-once animate-duration-[2000ms] animate-ease-linear !bg-custom-blue-1 !focus:bg-custom-blue-1 focus:border-custom-geek-blue-7"
            autoFocus
            size="large"
            value={answer}
            onChange={handleChange}
            autoSize={{ minRows: 3, maxRows: 10 }}
            maxLength={WEBSOCKET_ANSWER_MAX_LENGTH}
            {...(LearningConfig.copyPasteEnabled
              ? { onPaste: (e) => e.preventDefault() }
              : {})}
          />
        </ScrolledItemWrapper>
      )}
    </ContentLayout>
  );
};
