import { blue, gray } from '@ant-design/colors';
import { TeamOutlined, UserOutlined } from '@tml-component-library/src/icons';
import { messages } from '../messages';

export const getMessageProps = (isOdd: boolean) => {
  if (isOdd) {
    return {
      background: '',
      animation: '',
      tagIcon: <TeamOutlined />,
      tagColor: gray[1],
      tagMessage: messages.tutor,
    };
  }

  return {
    background: 'bg-sky-50',
    animation:
      'animate-fade animate-once animate-duration-300 animate-ease-linear',
    tagIcon: <UserOutlined />,
    tagColor: blue[3],
    tagMessage: messages.you,
  };
};
