import { useCallback, useState } from 'react';
import QueueAnim from 'rc-queue-anim';
import { CheckboxButton, CheckboxButtonProps } from './CheckboxButton';

type CheckboxButtonListProps = {
  buttonsProps: Omit<CheckboxButtonProps, 'onClick' | 'state'>[];
  correctValues: CheckboxButtonProps['value'][];
  onChange: (value: any) => void;
  showResults?: boolean;
  multiple?: boolean;
};

export const CheckboxButtonList = ({
  buttonsProps,
  correctValues,
  onChange,
  showResults = false,
  multiple = false,
}: CheckboxButtonListProps) => {
  const [checkedItems, setCheckedItems] = useState<
    CheckboxButtonProps['value'][]
  >([]);

  const addCheckedItem = useCallback(
    (buttonValue: CheckboxButtonProps['value']) => {
      setCheckedItems((prevState) => {
        const newState = multiple ? [...prevState, buttonValue] : [buttonValue];

        onChange(newState);

        return newState;
      });
    },
    [onChange]
  );

  const removeCheckedItem = useCallback(
    (buttonValue: CheckboxButtonProps['value']) => {
      setCheckedItems((prev) => {
        const newState = prev.filter((value) => value !== buttonValue);

        onChange(newState);

        return newState;
      });
    },
    [onChange]
  );

  const toggleCheck = useCallback(
    (buttonValue: CheckboxButtonProps['value']) => {
      if (checkedItems.includes(buttonValue)) {
        removeCheckedItem(buttonValue);
      } else {
        addCheckedItem(buttonValue);
      }
    },
    [addCheckedItem, removeCheckedItem, checkedItems]
  );

  return (
    <div className="w-full">
      <QueueAnim
        interval={500}
        type="scaleY"
      >
        {buttonsProps.map(({ value, disabled, ...props }) => {
          const isChecked = checkedItems.includes(value);
          let state: CheckboxButtonProps['state'] = isChecked
            ? 'checked'
            : 'initial';

          if (showResults) {
            const incorrectState = isChecked ? 'incorrect' : 'initial';
            const isCorrectValue = correctValues.includes(value);

            if (isCorrectValue && isChecked) {
              state = 'correct';
            } else if (isCorrectValue && !isChecked) {
              state = 'uncheckedCorrect';
            } else {
              state = incorrectState;
            }
          }

          return (
            <div
              key={value}
              className="mb-4"
            >
              <CheckboxButton
                {...props}
                value={value}
                state={state}
                onClick={toggleCheck}
                disabled={disabled || showResults}
              />
            </div>
          );
        })}
      </QueueAnim>
    </div>
  );
};
