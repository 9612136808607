import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import {
  ActivateUserResponse,
  UpdateUserResponse,
  UserEntity,
  AssignedKnowledgeBase,
  ManageUserRequest,
} from './types';

export const userApi = createApi({
  reducerPath: 'user',
  tagTypes: ['userDetails', 'userKbList'],
  baseQuery: axiosQuery({ baseUrl: '/user' }),
  endpoints: (builder) => ({
    userSelf: builder.query<UserEntity, void>({
      query: () => ({
        url: '/self',
      }),
      providesTags: ['userDetails'],
    }),

    activateUser: builder.mutation<ActivateUserResponse, void>({
      query: () => ({
        url: '/activate',
        method: 'POST',
        skipNotAuthorizedInterceptor: true,
      }),
    }),

    updateUser: builder.mutation<UpdateUserResponse, ManageUserRequest>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['userDetails'],
    }),

    userAssignedKbList: builder.query<AssignedKnowledgeBase[], string>({
      query: (userId) => ({
        url: `/${userId}/assigned-kbs`,
      }),
      providesTags: ['userKbList'],
    }),
  }),
});

export const {
  useUserSelfQuery,
  useActivateUserMutation,
  useUpdateUserMutation,
  useUserAssignedKbListQuery,
} = userApi;
