import { useEffect, useState } from 'react';

const MIN_SCREEN_WIDTH = 1280;
const MIN_SCREEN_HEIGHT = 690;

export const useScreenSizeLimit = (
  minWidth: number = MIN_SCREEN_WIDTH,
  minHeight: number = MIN_SCREEN_HEIGHT
) => {
  const [isLess, setIsLess] = useState(false);

  useEffect(() => {
    const listener = () => {
      if (window.innerWidth < minWidth || window.innerHeight < minHeight)
        return setIsLess(true);

      setIsLess(false);
    };

    listener();
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  return { isLess };
};
