import { ThemeConfig } from 'antd';

export const antTheme = {
  token: {
    fontFamily: 'Roboto',
  },
  components: {
    Form: {
      itemMarginBottom: 16,
      fontSize: 12,
    },
    Typography: {
      colorTextSecondary: '#00000073',
    },
  },
} as ThemeConfig;
