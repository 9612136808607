import { useEffect, useRef } from 'react';

export const useScrollIntoView = (
  shouldBeScrolled: boolean,
  delay: number,
  options: ScrollIntoViewOptions | undefined
) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = ref?.current;

    if (current && shouldBeScrolled) {
      /*
       * Timeout:
       *   - Typing animation delay
       *   - To be sure that component and children are rendered
       */
      setTimeout(() => {
        current.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'smooth',
          ...options,
        });
      }, delay);
    }
  }, [ref?.current, shouldBeScrolled]);

  return {
    ref,
  };
};
