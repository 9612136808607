import { backgroundPlaceholder } from '@tml-component-library/src/icons';
import { messages } from '../messages';

interface ImageViewProps {
  path?: string;
  alt?: string;
}

export const ImageView = ({ path, alt }: ImageViewProps) => (
  <div className="flex items-center justify-center h-full">
    <img
      className="animate-fade animate-once animate-duration-[1500ms] animate-ease-linear rounded-lg"
      style={{ objectFit: 'scale-down', maxHeight: '100%', maxWidth: '100%' }}
      src={path || backgroundPlaceholder}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // Prevents looping on error
        currentTarget.src = backgroundPlaceholder;
      }}
      alt={alt || messages.preview}
    />
  </div>
);
