import { useEffect, useRef } from 'react';

type Callback = (current: HTMLDivElement) => void;

export const useResizeObserver = (callback: Callback) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = ref?.current;

    if (!current) return;

    const listener = () => {
      callback(current);
    };

    const observer = new ResizeObserver(listener);
    observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
  }, [ref?.current]);

  return {
    ref,
  };
};
