import { CommonEntity } from '../types';

export enum UserStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface UserMetadata {
  authorities: UserAuthoritiesEntity[];
}

export interface UserProfile {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  phoneNumber: string;
  phoneNumberType: string;
  secondPhoneNumber: string;
  secondPhoneNumberType: string;
  linkedInUrl: string;
  startDate: string;
  courseNames: string[];
}

export enum UserAuthoritiesEntity {
  ROLE_STAFF = 'ROLE_STAFF',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_STUDENT = 'ROLE_STUDENT',
}

export interface UserEntity {
  id: string;
  staffId: string;
  adminId: string;
  companyId: string;
  department: CommonEntity;
  role: CommonEntity;
  cvFile: CommonEntity;
  status: UserStatus;
  profile: UserProfile;
  metadata: UserMetadata;
}

export type ActivateUserResponse = {
  accessToken: string;
  refreshToken: string;
};

export interface ManageUserRequest {
  id?: string;
  companyId: string;
  department?: Partial<CommonEntity>;
  role?: Partial<CommonEntity>;
  cvFile?: Partial<CommonEntity>;
  status?: UserStatus;
  profile: Partial<UserProfile>;
  userRole?: UserAuthoritiesEntity;
}

export type UpdateUserResponse = Required<UserEntity>;

export enum LearningStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export interface AssignedKnowledgeBase {
  userId: string;
  roleId: string;
  knowledgeBaseId: string;
  knowledgeBaseVersion: number;
  knowledgeBaseName: string;
  kbCreatedAt: string;
  kbAssignedAt: string;
  kbCompletedAt: string;
  learningId: string;
  learningStatus: LearningStatus;
  learningStartAt: string;
  learningFinishedAt: string;
}
