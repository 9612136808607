import { Meta } from '@tml-component-library/src/components/Meta';
import { SignInError } from 'features/sign-in-error';

export const SignInErrorPage = () => (
  <>
    <Meta title="Error" />

    <SignInError />
  </>
);
