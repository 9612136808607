import { createContext, ReactNode, useMemo } from 'react';
import { useUserSelfQuery } from 'api/user/user.api';
import { UserEntity } from 'api/user/types';

type UserContextType = {
  isError: boolean;
  isFetching: boolean;
  data?: UserEntity;
};

type UserProviderType = {
  children: ReactNode;
};

export const UserContext = createContext({} as UserContextType);
export const UserProvider = ({ children }: UserProviderType) => {
  const { data, isError, isFetching } = useUserSelfQuery();

  const contextValue = useMemo(
    () => ({
      isError,
      data,
      isFetching,
    }),
    [isError, data, isFetching]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
