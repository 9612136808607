import {
  LaptopOutlined,
  SettingOutlined,
} from '@tml-component-library/src/icons';
import { NavigationLayout } from 'common/navigation-layout';
import { Routes } from 'router/routes';
import { messages } from './messages';

const NAVIGATION_OPTIONS = [
  {
    key: Routes.courses(),
    icon: <LaptopOutlined />,
    label: messages.courses,
  },
  {
    key: Routes.settings(),
    icon: <SettingOutlined />,
    label: messages.settings,
  },
];

export const HomePage = () => <NavigationLayout items={NAVIGATION_OPTIONS} />;
