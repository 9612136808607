import { ReactNode } from 'react';
import classNames from 'classnames';
import { TransitionNotification } from '../components/TransitionNotification';

interface ContentLayoutProps {
  children: ReactNode;
  isCentralized?: boolean;
  contentClassName?: string;
  header?: ReactNode;
  footer?: ReactNode;
  showTransitionNotification?: boolean;
  transitionMessage?: string;
}

/*
 * Behavior:
 *   - parent div - flexbox styles applied
 *   - child div - overflow auto to achieve the scroll when it is needed
 */
export const ContentLayout = ({
  contentClassName,
  children,
  header,
  footer,
  isCentralized = false,
  showTransitionNotification,
  transitionMessage,
}: ContentLayoutProps) => (
  <div
    className={classNames(
      'flex flex-col w-full h-full',
      isCentralized ? 'justify-center' : 'justify-between'
    )}
  >
    {header}

    <div className={classNames('overflow-auto py-4 pl-4', contentClassName)}>
      {children}
      {showTransitionNotification && (
        <TransitionNotification message={transitionMessage} />
      )}
    </div>

    {footer}
  </div>
);
