import { ReactNode } from 'react';
import { SCROLL_ANIMATION_DELAY } from 'shared/constants';
import { useScrollIntoView } from 'hooks';

interface ScrolledItemWrapperProps {
  children: ReactNode;
  shouldBeScrolled: boolean;
  options?: ScrollIntoViewOptions;
  className?: string;
  delay?: number;
}

export const ScrolledItemWrapper = ({
  children,
  shouldBeScrolled,
  className,
  options,
  delay = SCROLL_ANIMATION_DELAY,
}: ScrolledItemWrapperProps) => {
  const { ref } = useScrollIntoView(shouldBeScrolled, delay, options);

  return (
    <div
      ref={ref}
      className={className}
    >
      {children}
    </div>
  );
};
