import { Meta } from '@tml-component-library/src/components/Meta';
import { CoursesGrid } from 'features/courses-grid/CoursesGrid';

export const CoursesPage = () => {
  return (
    <>
      <Meta title="Courses" />
      <CoursesGrid />
    </>
  );
};
