import { Outlet } from 'react-router-dom';
import { useUser } from 'common/user/useUser';
import { Spinner } from '@tml-component-library/src';
import { UserAuthoritiesEntity } from 'api/user/types';

interface ProtectedRouteProps {
  userRole: UserAuthoritiesEntity;
}

export const ProtectedRoute = ({ userRole }: ProtectedRouteProps) => {
  const { data: { metadata } = {}, isFetching } = useUser();

  if (isFetching) return <Spinner />;

  if (metadata?.authorities?.includes(userRole)) return <Outlet />;

  return <div>You don`t have permissions to access the resource</div>;
};
