import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { RoleEntity, RoleDetailsRequest } from './types';

export const roleApi = createApi({
  reducerPath: 'role',
  tagTypes: ['roleDetails'],
  baseQuery: axiosQuery({ baseUrl: '/role' }),
  endpoints: (builder) => ({
    roleDetails: builder.query<RoleEntity, RoleDetailsRequest>({
      query: ({ id, ...params }) => ({
        url: `/${id}`,
        params,
      }),
      providesTags: ['roleDetails'],
    }),
  }),
});

export const { useLazyRoleDetailsQuery } = roleApi;
