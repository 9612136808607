import { Empty, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import { DAY_FORMAT } from '@tml-component-library/src';
import { AssignedKnowledgeBase, LearningStatus } from 'api/user/types';
import { CourseCard, CourseCardStatus } from './CourseCard';
import { messages } from '../messages';

type CoursesSectionProps = {
  isLoading: boolean;
  title: string;
  courses: AssignedKnowledgeBase[];
};

export const CoursesSection = ({
  title,
  courses,
  isLoading,
}: CoursesSectionProps) => (
  <Spin spinning={isLoading}>
    <div className="flex flex-col gap-4 overflow-hidden -m-6">
      <Typography.Title
        style={{ margin: 0 }}
        className="font-medium pt-6 pl-6"
        level={4}
      >
        {title}
      </Typography.Title>

      <div className="flex flex-wrap gap-4 overflow-auto px-6 pb-8">
        {courses?.length ? (
          courses.map(
            ({
              learningStatus,
              knowledgeBaseName,
              knowledgeBaseId,
              kbAssignedAt,
              kbCompletedAt,
              roleId,
            }) => {
              const isCompleted = learningStatus === LearningStatus.FINISHED;
              const date = dayjs(
                isCompleted ? kbCompletedAt : kbAssignedAt
              ).format(DAY_FORMAT);

              return (
                <CourseCard
                  key={knowledgeBaseId}
                  roleId={roleId}
                  courseName={knowledgeBaseName}
                  knowledgeBaseId={knowledgeBaseId}
                  statusUpdateTimestamp={date}
                  status={
                    isCompleted
                      ? CourseCardStatus.COMPLETED
                      : CourseCardStatus.READY
                  }
                />
              );
            }
          )
        ) : (
          <Empty
            className="ml-36"
            description={messages.noCourses}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      </div>
    </div>
  </Spin>
);
