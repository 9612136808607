import { userApi } from 'api/user/user.api';
import { authApi } from 'api/auth/auth.api';
import { learningStepsApi } from 'api/learning-steps/learning-steps.api';
import { roleApi } from 'api/role/role.api';
import { fileApi } from '../api/file/file.api';

export const rootReducer = {
  [authApi.reducerPath]: authApi.reducer,
  [fileApi.reducerPath]: fileApi.reducer,
  [learningStepsApi.reducerPath]: learningStepsApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
};

export const middlewares = [
  authApi.middleware,
  fileApi.middleware,
  learningStepsApi.middleware,
  roleApi.middleware,
  userApi.middleware,
];
