import { Meta } from '@tml-component-library/src';
import { LearningProcess } from 'features/learning-proccess';

export const LearningProcessPage = () => {
  return (
    <>
      <Meta title="Learning process" />

      <LearningProcess />
    </>
  );
};
