import {
  Progress as AntProgress,
  ProgressProps as AntProgressProps,
} from 'antd';

type ProgressProps = AntProgressProps;

export const Progress = (props: ProgressProps) => {
  return <AntProgress {...props} />;
};
