import { Client, IFrame, IMessage } from '@stomp/stompjs';
import * as humps from 'humps';
import { authRepository } from 'common/auth/auth.repository';
import { AppConfig } from 'configs';

interface DisconnectOptionsProps {
  force?: boolean;
}

const RECONNECTION_TIME = 2500;

export class StompWebSocket {
  private _ws: Client | undefined;

  private _isError: boolean | undefined;

  public async create() {
    if (!this._ws?.connected) {
      const { accessToken: Authorization } = authRepository.getAuthTokens();

      this._ws = new Client({
        brokerURL: AppConfig.wsApiBaseUrl,
        connectHeaders: { Authorization },
        reconnectDelay: RECONNECTION_TIME,
      });
    }
    this._isError = false;
  }

  get isError(): boolean {
    return Boolean(this._isError);
  }

  set isError(value: boolean) {
    this._isError = value;
  }

  private waitUntilConnected(onConnect: () => void) {
    return new Promise((resolve, reject) => {
      this._ws!.onConnect = (frame: IFrame) => {
        onConnect();
        this._isError = false;
        resolve(frame);
      };
      this._ws!.onStompError = (frame: IFrame) => {
        this._isError = true;
        reject(frame);
      };
      this._ws!.onWebSocketError = (frame: IFrame) => {
        this._isError = true;
        reject(frame);
      };
    });
  }

  public async connect(onConnect: () => void) {
    // Skip creation while it is connected
    if (!this._ws?.connected) {
      this._ws?.activate();

      await this.waitUntilConnected(onConnect);
    }
  }

  public subscribe(destination: string, callback: (data: any) => void) {
    const decamelize = (event: IMessage) =>
      callback(humps.camelizeKeys(JSON.parse(event.body)));

    this._ws?.subscribe(destination, decamelize);
  }

  public publish(destination: string, data: any) {
    const body = JSON.stringify(humps.decamelizeKeys(data));

    this._ws?.publish({ destination, body });
  }

  public async disconnect(options?: DisconnectOptionsProps) {
    await this._ws?.deactivate(options);
  }
}
