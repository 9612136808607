import { Meta } from '@tml-component-library/src/components/Meta';
import { Settings } from 'features/settings/Settings';

export const SettingsPage = () => {
  return (
    <>
      <Meta title="Student settings" />
      <Settings />
    </>
  );
};
