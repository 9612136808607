import { Typography } from 'antd';
import classNames from 'classnames';
import { DialogEntity, DialogRoles } from 'api/learning-steps/types';
import { getMessageProps } from './utils';
import { MarkdownTypeAnimation } from '../MardownTypeAnimation';
import { ScrolledItemWrapper } from '../ScrolledItemWrapper';

interface OpenChatListProps {
  messages: DialogEntity[];
  onTypingDone: (typing: boolean) => void;
}

export const OpenChatList = ({ messages, onTypingDone }: OpenChatListProps) => (
  <>
    {messages.map(({ role, content }, index) => {
      const isAssistant = role === DialogRoles.ASSISTANT;
      const isLastMessage = index === messages.length - 1;

      const { background, animation } = getMessageProps(isAssistant);

      const message = content?.trim() || '';

      return (
        <div
          key={`${message}-${index}`.toString()}
          className={classNames(
            'flex justify-start items-center w-full p-2 rounded-lg',
            background,
            animation
          )}
        >
          <Typography.Text
            className={classNames('text-sm laptop:text-base desktop:text-xl', {
              'font-gelasio': isAssistant,
            })}
          >
            {isAssistant ? (
              <MarkdownTypeAnimation
                onTypingDone={() => onTypingDone(true)}
                markdown={message}
                onTypingStarted={() => onTypingDone(false)}
              />
            ) : (
              <ScrolledItemWrapper
                shouldBeScrolled={isLastMessage}
                options={{ block: 'end' }}
              >
                {message}
              </ScrolledItemWrapper>
            )}
          </Typography.Text>
        </div>
      );
    })}
  </>
);
