import { Meta } from '@tml-component-library/src/components/Meta';
import { SignInSuccess } from 'features/sign-in-success';

export const SignInSuccessPage = () => (
  <>
    <Meta title="Success" />

    <SignInSuccess />
  </>
);
