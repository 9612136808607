import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Typography } from 'antd';
import { ContentEntity } from 'api/learning-steps/types';
import { MarkdownTypeAnimation } from 'components/MardownTypeAnimation';
import { ContentLayout } from '../layouts/ContentLayout';

interface ComponentPreviewProps extends Partial<ContentEntity> {
  onAnimationDone: Dispatch<SetStateAction<boolean>>;
  showTransitionNotification?: boolean;
}

export const ComponentPreview = ({
  description,
  onAnimationDone,
  showTransitionNotification = false,
}: ComponentPreviewProps) => {
  const [isTypingDescriptionDone, setIsTypingDescriptionDone] =
    useState(!description);

  useEffect(() => {
    if (isTypingDescriptionDone) {
      onAnimationDone(true);
    }

    return () => onAnimationDone(false);
  }, [isTypingDescriptionDone]);

  return (
    <ContentLayout
      isCentralized
      showTransitionNotification={showTransitionNotification}
    >
      {description && (
        <Typography.Paragraph className="text-sm laptop:text-base desktop:text-xl font-gelasio">
          <MarkdownTypeAnimation
            markdown={description}
            onTypingDone={() => setIsTypingDescriptionDone(true)}
          />
        </Typography.Paragraph>
      )}
    </ContentLayout>
  );
};
