import { compileMarkdownToJSX } from '@tml-component-library/src';
import { MarkdownToJSX } from 'markdown-to-jsx';
import { LearningConfig } from 'configs';
import { TypeAnimation, TypeTextAnimationProps } from '../TypeAnimation';

export interface MarkdownTypeAnimationProps
  extends Partial<TypeTextAnimationProps> {
  markdown: string;
  markdownProps?: MarkdownToJSX.Options;
}

/*
 * Important!
 *   - compiler - produces the finalized JSX which is very important for Type Text animation
 *   - cursor: false - due to the fact that we are working with nested elements it will not be possible to display in the correct place
 */

export const MarkdownTypeAnimation = ({
  markdown,
  markdownProps,
  ...props
}: MarkdownTypeAnimationProps) => (
  <TypeAnimation
    {...(LearningConfig.copyPasteEnabled ? { className: 'select-none' } : {})}
    {...props}
  >
    {compileMarkdownToJSX(markdown, { ...markdownProps })}
  </TypeAnimation>
);
