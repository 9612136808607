import { StorageService } from 'shared';

class AuthRepository {
  private readonly client = StorageService.getLocalStorageService();

  private readonly keys = {
    session: 'auth.has-session',
    accessToken: 'auth.accessToken',
    refreshToken: 'auth.refreshToken',
  };

  public isHasSession() {
    return Boolean(this.client.get(this.keys.session));
  }

  public setHasSession(value: boolean) {
    return this.client.set(this.keys.session, value);
  }

  public setAuthTokens(value: { accessToken: string; refreshToken?: string }) {
    this.client.set(this.keys.refreshToken, value.refreshToken);
    this.client.set(this.keys.accessToken, value.accessToken);
  }

  public clearAuthTokens() {
    this.client.remove(this.keys.refreshToken);
    this.client.remove(this.keys.accessToken);
  }

  public getAuthTokens() {
    return {
      accessToken: this.client.get(this.keys.accessToken),
      refreshToken: this.client.get(this.keys.refreshToken),
    };
  }

  public clear() {
    Object.values(this.keys).forEach((key) => this.client.remove(key));
  }
}

export const authRepository = new AuthRepository();
