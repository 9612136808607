import { NavLink } from 'react-router-dom';
import { MenuProps, theme } from 'antd';
import {
  Button,
  colors,
  NavigationBar,
  UserLabel,
} from '@tml-component-library/src';
import { Routes } from 'router/routes';
import { Logo, LogoutOutlined } from '@tml-component-library/src/icons';
import { useUser } from '../user/useUser';
import { useAuth } from '../auth';
import { messages } from './messages';

interface NavbarProps {
  menuProps: MenuProps;
}

export const Navbar = ({ menuProps }: NavbarProps) => {
  const { data, isFetching } = useUser();
  const { signOut } = useAuth();

  const navMenuProps = {
    className: 'w-64',
    ...menuProps,
  };

  const {
    token: { colorPrimaryText },
  } = theme.useToken();

  const handleSignOut = () => {
    signOut();
    window.location.reload();
  };

  return (
    <NavigationBar
      menuProps={navMenuProps}
      header={
        <NavLink to={Routes.root()}>
          <Logo />
        </NavLink>
      }
      footer={
        <div className="flex flex-col gap-2">
          {!isFetching && data && (
            <UserLabel
              avatarProps={{
                size: 64,
                style: {
                  background: colorPrimaryText,
                },
              }}
              username={`${data?.profile.firstName} ${data?.profile.lastName}`}
              email={data?.profile.email || ''}
            />
          )}
          <div className="py-2 px-6">
            <Button
              // TODO: refactor to use color from theme
              className="text-start"
              icon={<LogoutOutlined className="text-custom-black-trasparent" />}
              type="text"
              block
              style={{
                padding: '0',
                color: colors['black-transparent'],
              }}
              onClick={handleSignOut}
            >
              {messages.logOut}
            </Button>
          </div>
        </div>
      }
    />
  );
};
