import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { TextInput } from '@tml-component-library/src';
import { penPaperImage } from '@tml-component-library/src/icons';
import { useResizeBasedParentObserver } from 'hooks';
import { WEBSOCKET_ANSWER_MAX_LENGTH } from 'shared';
import { LearningConfig } from 'configs';
import { calculateInputCoords } from './utils';
import { DialogEntity } from '../../api/learning-steps/types';

interface ILEInputProps {
  answer: string;
  onAnswer: Dispatch<SetStateAction<string>>;
  messages: DialogEntity[];
  disabled: boolean;
  setIsDisabledNextStep: Dispatch<SetStateAction<boolean>>;
  isTransitionToNextStepEnabled: boolean;
}

export const ILEInput = ({
  answer,
  onAnswer,
  disabled,
  setIsDisabledNextStep,
  messages,
  isTransitionToNextStepEnabled,
}: ILEInputProps) => {
  const {
    imageRef,
    parentRef,
    coordinates: { imageGap, ...coordinates } = {},
  } = useResizeBasedParentObserver(calculateInputCoords);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newAnswer = e.target.value;

    onAnswer(newAnswer);
  };

  useEffect(() => {
    const prevAnswer = messages[messages.length - 1]?.content;

    if (prevAnswer === answer) {
      setIsDisabledNextStep(true);
    } else {
      setIsDisabledNextStep(false);
    }

    if (isTransitionToNextStepEnabled) {
      setIsDisabledNextStep(false);
    }

    return () => {
      setIsDisabledNextStep(false);
    };
  }, [messages, setIsDisabledNextStep, answer, isTransitionToNextStepEnabled]);

  return (
    <div
      ref={parentRef}
      className="flex justify-end items-center max-w-full max-h-full h-full w-full"
    >
      <div style={coordinates}>
        <TextInput.TextArea
          disabled={disabled}
          autoFocus
          value={answer}
          onChange={handleChange}
          autoSize
          maxLength={WEBSOCKET_ANSWER_MAX_LENGTH}
          className="text-xs laptop:text-sm desktop:text-base"
          rootClassName="overflow-auto !min-h-full max-h-full !bg-custom-natural-gray-3 focus:bg-transparent hover:bg-transparent focus:shadow-none"
          {...(LearningConfig.copyPasteEnabled
            ? { onPaste: (e) => e.preventDefault() }
            : {})}
        />
      </div>

      <img
        ref={imageRef}
        style={{
          objectFit: 'contain',
          maxHeight: '100%',
          maxWidth: '100%',
          marginLeft: imageGap ? -imageGap : 0,
        }}
        src={penPaperImage}
        alt="Interactive Learning Exercise"
      />
    </div>
  );
};
