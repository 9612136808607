import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Typography, theme } from 'antd';
import { Button } from '@tml-component-library/src';
import { useLazyRoleDetailsQuery } from 'api/role/role.api';
import { Routes } from 'router/routes';
import { messages } from '../messages';

export enum CourseCardStatus {
  READY,
  COMPLETED,
}

type CourseItemProps = {
  courseName: string;
  status: CourseCardStatus;
  statusUpdateTimestamp: string;
  knowledgeBaseId: string;
  roleId?: string;
  imageSrc?: string;
};

const CardState = {
  [CourseCardStatus.READY]: {
    text: messages.start,
    headerBgColorClass: 'bg-custom-base-blue-6',
    statusTimeStampDescription: messages.availableSince,
  },
  [CourseCardStatus.COMPLETED]: {
    text: messages.restart,
    headerBgColorClass: 'bg-custom-base-green-6',
    statusTimeStampDescription: messages.completed,
  },
};

export const CourseCard = ({
  status,
  imageSrc,
  statusUpdateTimestamp,
  courseName,
  roleId,
  knowledgeBaseId,
}: CourseItemProps) => {
  const navigate = useNavigate();
  const {
    token: { boxShadowSecondary, borderRadiusLG },
  } = theme.useToken();

  const [getRoleById, { data: { titleAbbreviated: roleName = '' } = {} }] =
    useLazyRoleDetailsQuery();

  useEffect(() => {
    if (roleId) getRoleById({ id: roleId });
  }, [roleId]);

  const handleClick = () => navigate(Routes.course(knowledgeBaseId));

  const currentState = CardState[status];
  const title = `${courseName} ${roleName}`;
  const description = `${currentState.statusTimeStampDescription} ${statusUpdateTimestamp}`;

  return (
    <div
      style={{ boxShadow: boxShadowSecondary, borderRadius: borderRadiusLG }}
      className="w-[360px] flex flex-col gap-2 bg-custom-neutral-gray-1"
    >
      <div
        style={{
          borderRadius: `${borderRadiusLG}px ${borderRadiusLG}px 0 0`,
        }}
        className={`flex justify-center ${currentState.headerBgColorClass} bg-custom-base-blue-6`}
      >
        <Typography.Text className="text-xs !text-custom-base-blue-1 py-1">
          {messages.ready}
        </Typography.Text>
      </div>

      {imageSrc && (
        <div className="px-2">
          <img
            width="344"
            height="159"
            src={imageSrc}
            alt="placeholder"
          />
        </div>
      )}

      <div className="py-4 px-6 flex gap-2.5 justify-between items-center">
        <div className="flex flex-col overflow-hidden">
          <Typography.Text
            ellipsis={{ tooltip: title }}
            strong
          >
            {title}
          </Typography.Text>
          <Typography.Text ellipsis={{ tooltip: description }}>
            {description}
          </Typography.Text>
        </div>

        <Button
          type="primary"
          onClick={handleClick}
        >
          {currentState.text}
        </Button>
      </div>
    </div>
  );
};
