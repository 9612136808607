import { ReactNode } from 'react';
import { Button } from '../Button';
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
} from '../../icons';
import { colors } from '../../theme';

type CheckboxState =
  | 'correct'
  | 'incorrect'
  | 'checked'
  | 'initial'
  | 'uncheckedCorrect';

export type CheckboxButtonProps = {
  buttonText: ReactNode;
  onClick: (value: any) => void;
  value: string | number;
  state?: CheckboxState;
  disabled?: boolean;
};

const icons: Record<CheckboxState, ReactNode> = {
  correct: <CheckOutlined style={{ color: colors['green-5'] }} />,
  checked: <CheckOutlined />,
  incorrect: <CloseOutlined />,
  uncheckedCorrect: null,
  initial: null,
};

export const CheckboxButton = ({
  buttonText,
  onClick,
  value,
  state = 'initial',
  disabled,
}: CheckboxButtonProps) => {
  const handleClick = () => {
    onClick(value);
  };
  let style = {};

  if (state === 'correct' || state === 'uncheckedCorrect') {
    style = { borderColor: colors['green-5'], color: colors['green-5'] };
  } else if (state === 'checked') {
    style = {
      borderColor: colors['blue-7'],
      color: colors['blue-7'],
      backgroundColor: colors['blue-1'],
    };
  }

  return (
    <div className="flex gap-2">
      {state === 'uncheckedCorrect' && (
        <ExclamationCircleFilled className="text-2xl !text-[#D9363E]" />
      )}

      <Button
        size="large"
        className={`w-full h-max flex items-center ${disabled ? 'pointer-events-none' : ''}`}
        danger={state === 'incorrect'}
        style={style}
        ghost={state !== 'initial'}
        type={state !== 'initial' ? 'primary' : undefined}
        onClick={handleClick}
      >
        {icons[state]}
        <span className="max-w-full text-xs laptop:text-sm desktop:text-base text-wrap text-left">
          {buttonText}
        </span>
      </Button>
    </div>
  );
};
