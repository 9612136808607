export const messages = {
  exitCourse: 'Exit Course',
  restartCourse: 'Restart Course',
  preview: 'Preview',

  outstanding:
    'Outstanding work! Your dedication and hard work are truly paying off.',
  perfect:
    'Perfect score! Your attention to detail and preparation have truly shone through.',
  congratulations:
    'Congratulations! Your excellent performance is a testament to your commitment.',
  amazing:
    'Amazing job! You’ve demonstrated exceptional understanding and skill.',

  mistake:
    'Mistakes are a part of learning. Keep pushing forward, and you’ll get there!',
  discouraged:
    'Don’t be discouraged by errors; they’re stepping stones to success.',
  improve:
    'Every mistake is an opportunity to learn and improve. Keep up the great effort!',
  keep: 'Remember, progress is more important than perfection. Keep striving, and you’ll achieve your goals!',

  leaveCourse: 'You’re about to leave your course!',
  dropProgressToTopic: (topicName: string = 'current topic') => `
    By leaving your learning session your current progress will jump back
    to the first lesson of ${topicName}.
  `,

  selectDownArrow: 'Select the down arrow when you’re ready',
  closeLearningExperience:
    'Select the down arrow to close the Learning Experience',
  youLearnedWithUs: 'You learned with us about:',
  courseCompleted: 'Course Completed',
  continue: 'Continue...',
};
