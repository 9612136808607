import { useEffect } from 'react';
import Typist, { TypistProps, Splitter } from 'react-typist-component';
import classNames from 'classnames';
import { useResizeObserver } from 'hooks';
import { LearningConfig } from 'configs';
import { rowSplitter } from './utils';

export interface TypeTextAnimationProps extends TypistProps {
  onTypingStarted?: () => void;
  splitter?: Splitter;
  typingDelay?: number;
  className?: string;
}

/*
 * Behavior:
 *   - parent - relative
 *     - invisible child - invisible div with content rendered to achieve height of the content
 *     - absolute child - absolute Typist (div) content typed animation without jumping
 *   - autoscroll to the end when typing container change size - typing new string
 */
export const TypeAnimation = ({
  typingDelay = LearningConfig.defaultWordDelayTyping,
  splitter = rowSplitter,
  children,
  className,
  onTypingStarted,
  ...props
}: TypeTextAnimationProps) => {
  useEffect(() => {
    onTypingStarted?.();
  }, []);

  /*
   * Scroll to end of the current typing line
   */
  const { ref } = useResizeObserver((current) => {
    current.scrollIntoView({
      block: 'end',
      inline: 'nearest',
      behavior: 'smooth',
    });
  });

  return (
    <div className="relative">
      <div className="invisible">{children}</div>
      <div
        className={classNames('absolute top-0 left-0', className)}
        ref={ref}
      >
        <Typist
          splitter={splitter}
          typingDelay={typingDelay}
          {...props}
        >
          {children}
        </Typist>
      </div>
    </div>
  );
};
