import { Button, Form } from 'antd';
import {
  ACCEPTED_CV_FORMATS,
  messages,
  UploadDragger,
  UploadDraggerOnChangeHandler,
  UploadedList,
  useFormContext,
} from '@tml-component-library/src';
import { SettingsFormFields } from '../constants';
import { SettingsFormValues } from '../types';

type SettingsFormProps = {
  defaultValues: Partial<SettingsFormValues>;
};

export const SettingsForm = ({ defaultValues }: SettingsFormProps) => {
  const { values, submit, isSubmitting, setFieldValue } = useFormContext();

  const onAttachFile: UploadDraggerOnChangeHandler = (value) => {
    setFieldValue(SettingsFormFields.RESUME, value.fileList[0]?.originFileObj);
  };

  const onReplaceButtonClick = () => {
    setFieldValue(SettingsFormFields.RESUME, undefined);
  };

  const onCancelClick = () => {
    setFieldValue(SettingsFormFields.RESUME, defaultValues?.resume);
  };

  const resume = values[SettingsFormFields.RESUME];

  const isReplaceFileMode = defaultValues?.resume && !resume;
  const isNewFile = resume instanceof File;

  const showActionButtons = isNewFile || isReplaceFileMode;

  return (
    <>
      <div className="h-full flex flex-col justify-between">
        <Form.Item
          name={SettingsFormFields.RESUME}
          label={messages.resumeUpload}
        >
          {resume && !isNewFile ? (
            <UploadedList
              name={values[SettingsFormFields.RESUME] as string}
              buttonText={messages.replace}
              onClick={onReplaceButtonClick}
            />
          ) : (
            <UploadDragger
              uploadProps={{
                beforeUpload: () => false,
                multiple: false,
                maxCount: 1,
                accept: ACCEPTED_CV_FORMATS.join(','),
                onChange: onAttachFile,
              }}
            />
          )}
        </Form.Item>
      </div>

      {showActionButtons ? (
        <div className="flex justify-end gap-2.5">
          <Button onClick={onCancelClick}>{messages.cancel}</Button>
          <Button
            disabled={isSubmitting || !resume}
            loading={isSubmitting}
            type="primary"
            onClick={submit}
          >
            {messages.save}
          </Button>
        </div>
      ) : null}
    </>
  );
};
