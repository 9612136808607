import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosQuery } from 'utils';
import { CreateFileRequest, FileEntity, FileUploadUrlResponse } from './types';

export const fileApi = createApi({
  reducerPath: 'file',
  tagTypes: ['fileList', 'fileDetails'],
  baseQuery: axiosQuery({ baseUrl: '/file' }),
  endpoints: (builder) => ({
    createFile: builder.mutation<FileEntity[], CreateFileRequest>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['fileList', 'fileDetails'],
    }),

    getFilesUploadUrl: builder.mutation<FileUploadUrlResponse, void>({
      query: (params) => ({
        url: '/upload-url',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useCreateFileMutation, useGetFilesUploadUrlMutation } = fileApi;
