import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { hideEmailPrefix } from '@tml-component-library/src/utils/hideEmailPrefix';
import { Routes } from 'router/routes';
import { useAuth } from 'common/auth';
import { messages } from './messages';

export const SignInSuccess = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { signIn, isLoading } = useAuth();

  const email = state?.email;
  const isLinkSent = state?.isLinkSent;

  if (!email)
    return (
      <Navigate
        to={Routes.signIn()}
        replace
      />
    );

  const handleResendInvitation = async () => {
    if (!isLoading) {
      try {
        await signIn({ email });
        navigate(Routes.signInSuccess(), {
          replace: true,
          state: { email, isLinkSent: true },
        });
      } catch (e) {
        navigate(Routes.signInError(), { state: { error: true } });
      }
    }
  };

  return (
    <div className="max-w-64 flex flex-col text-center">
      <Typography.Title level={5}>{messages.loginLinkSent}</Typography.Title>
      <Typography.Text className="mt-3 text-xs leading-5">
        {messages.sentToEmail} <b>{hideEmailPrefix(email || '')}.</b>{' '}
        {messages.lookMailbox}
      </Typography.Text>
      <Typography.Text className="mt-4 mb-2 text-xs leading-5">
        {messages.waitFewMinutes}{' '}
        {isLinkSent ? (
          <Typography.Text
            className="text-xs"
            type="secondary"
          >
            {messages.newSecureLinkSent} <MailOutlined />
          </Typography.Text>
        ) : (
          <Typography.Text
            className="text-xs cursor-pointer"
            underline
            onClick={handleResendInvitation}
          >
            {messages.resendLink}
          </Typography.Text>
        )}
      </Typography.Text>
    </div>
  );
};
