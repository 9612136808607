import { Typography } from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { MarkdownTypeAnimation } from 'components/MardownTypeAnimation';
import { ILEChatList } from 'components/ChatList';
import { DialogEntity } from 'api/learning-steps/types';
import { ContentLayout } from '../layouts/ContentLayout';

interface OpenQuestionProps {
  description?: string;
  messages: DialogEntity[];
  onAnimationDone: Dispatch<SetStateAction<boolean>>;
  showTransitionNotification?: boolean;
}

export const ILEQuestion = ({
  description,
  messages,
  onAnimationDone,
  showTransitionNotification,
}: OpenQuestionProps) => {
  const [isTypingDone, setIsTypingDone] = useState(false);

  return (
    <ContentLayout
      showTransitionNotification={showTransitionNotification}
      contentClassName="h-full flex flex-col justify-start gap-4"
    >
      {description && (
        <Typography.Paragraph className="text-sm laptop:text-base desktop:text-xl font-gelasio">
          <MarkdownTypeAnimation
            markdown={description}
            onTypingDone={() => {
              setIsTypingDone(true);
              onAnimationDone(true);
            }}
          />
        </Typography.Paragraph>
      )}
      {isTypingDone && (
        <ILEChatList
          messages={messages}
          onTypingDone={onAnimationDone}
        />
      )}
    </ContentLayout>
  );
};
