import { Typography } from 'antd';
import { ReactNode } from 'react';
import { Button, Progress } from '@tml-component-library/src';
import { CaretDownOutlined } from '@tml-component-library/src/icons';
import { messages } from '../messages';

type LearningProcessLayoutProps = {
  isBiggerRightContent: boolean;
  onNext: () => void;
  leftContent: ReactNode;
  rightContent: ReactNode;
  isNextDisabled: boolean;
  isProcessing: boolean;
  title?: string;
  progress?: number;
  onExit?: () => void;
  onRestart?: () => void;
};

export const LearningProcessLayout = ({
  isBiggerRightContent,
  onNext,
  leftContent,
  rightContent,
  title,
  progress,
  isNextDisabled,
  isProcessing,
  onExit,
  onRestart,
}: LearningProcessLayoutProps) => {
  const isProgressProvided = typeof progress === 'number';

  return (
    <div className="py-4 px-6 flex flex-col justify-between gap-4 bg-custom-natural-gray-3 h-screen">
      <div className="flex justify-between">
        <div className="flex gap-4">
          {onExit && <Button onClick={onExit}>{messages.exitCourse}</Button>}
          {onRestart && (
            <Button onClick={onRestart}>{messages.restartCourse}</Button>
          )}

          {/* OOS: <Button>sound</Button> */}
        </div>

        {/* OOS: <div className="flex items-center gap-4">
          <div>time</div>
          <Button>play/pause</Button>
        </div> */}
      </div>

      {title && (
        <div className="pl-12">
          <Typography.Title
            style={{ margin: 0 }}
            level={3}
          >
            {title}
          </Typography.Title>
        </div>
      )}

      <div className="h-full p-6 bg-white flex flex-1 justify-between gap-12 overflow-hidden">
        <div
          className={`relative h-full flex flex-col justify-between ${isBiggerRightContent ? 'w-[38%]' : 'w-[51%]'}`}
        >
          <div className="h-full overflow-hidden pb-20">{leftContent}</div>

          <div className="absolute bottom-0 pb-8 pt-2 w-full flex justify-center items-center">
            <Button
              className="animate-fade animate-once animate-duration-[1500ms] animate-ease-linear"
              size="large"
              shape="round"
              type="primary"
              disabled={isNextDisabled}
              loading={isProcessing}
              icon={<CaretDownOutlined className="align-middle" />}
              onClick={onNext}
            />
          </div>
        </div>

        <div
          className={`h-full p-4 ${isBiggerRightContent ? 'w-[62%]' : 'w-[49%]'}`}
        >
          {rightContent}
        </div>
      </div>

      {isProgressProvided && (
        <Progress
          showInfo={false}
          percent={progress}
        />
      )}
    </div>
  );
};
