import { Typography } from 'antd';
import { DialogEntity } from 'api/learning-steps/types';
import { MarkdownTypeAnimation } from '../MardownTypeAnimation';

interface ILEChatListProps {
  messages: DialogEntity[];
  onTypingDone: (typing: boolean) => void;
}

export const ILEChatList = ({ messages, onTypingDone }: ILEChatListProps) => (
  <>
    {messages.map(({ content }, index) => {
      return (
        <div
          key={`${content}-${index}`.toString()}
          className="animate-fade animate-once animate-duration-300 animate-ease-linear"
        >
          <Typography.Text className="text-sm laptop:text-base desktop:text-xl font-gelasio">
            <MarkdownTypeAnimation
              markdown={content}
              onTypingDone={() => onTypingDone(true)}
              onTypingStarted={() => onTypingDone(false)}
            />
          </Typography.Text>
        </div>
      );
    })}
  </>
);
