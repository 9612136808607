import { Outlet, useNavigate, useLocation } from 'react-router';
import { SelectEventHandler } from 'rc-menu/lib/interface';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { MainLayout } from '@tml-component-library/src/layouts';
import { Navbar } from '../navbar/Navbar';
import {
  getCurrentRootOption,
  getCurrentOptionFromNestedPath,
  NavigationItem,
} from './utils';

interface CommonLayoutProps {
  items: NavigationItem[];
}

export interface NavigationLayoutContext {
  onSetHeader: (node: ReactNode) => void;
}

export const NavigationLayout = ({ items }: CommonLayoutProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [header, setHeader] = useState<ReactNode>();
  const [currentMenuItem, setCurrentMenuItem] = useState<NavigationItem>(
    items[0]
  );

  /*
   * onSelect - triggered automatically by Ant Design
   * in case - every click should be listened - possible to change to "onClick"
   */
  const onSelect: SelectEventHandler = useCallback(
    ({ key }) => {
      const selected = getCurrentRootOption(items, key);

      navigate(selected!.key);
    },
    [navigate]
  );

  useEffect(() => {
    /*
     * Highlight current option: Listen navigation path's and set current
     */
    const currentOption = getCurrentOptionFromNestedPath(items, pathname);

    if (currentOption) setCurrentMenuItem(currentOption);

    /*
     * Set default route header: Listen only root navigation path's and set header
     */
    const currentRootOption = getCurrentRootOption(items, pathname);

    if (currentRootOption) setHeader(currentRootOption.label);
  }, [pathname]);

  const handleSetHeader = useCallback(
    (node: ReactNode) => setHeader(node),
    [setHeader]
  );

  return (
    <MainLayout
      sider={
        <Navbar
          menuProps={{ selectedKeys: [currentMenuItem?.key], items, onSelect }}
        />
      }
      header={header}
      content={
        <Outlet
          context={{ onSetHeader: handleSetHeader } as NavigationLayoutContext}
        />
      }
    />
  );
};
