export const messages = {
  currentCourses: 'Current Courses',
  pastCourses: 'Past Courses',
  noCourses: 'No Courses',
  ready: 'Ready',
  start: 'Start',
  restart: 'Restart',
  availableSince: 'Available since',
  completed: 'Completed',
};
