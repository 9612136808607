import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

class FileUploader {
  private static fileUploader: FileUploader;

  private httpClient: AxiosInstance;

  private isUploading = false;

  private error?: Error;

  private constructor() {
    this.httpClient = axios.create();
  }

  public static getInstance(): FileUploader {
    if (!FileUploader.fileUploader) {
      return new FileUploader();
    }

    return FileUploader.fileUploader;
  }

  public async uploadFile(
    url: string,
    file: File,
    config: AxiosRequestConfig
  ): Promise<void> {
    try {
      this.isUploading = true;

      await this.httpClient.put(url, file, config);

      this.isUploading = false;
    } catch (err) {
      this.isUploading = false;
      this.error = err as Error;

      throw err;
    }
  }

  public getIsUploading(): boolean {
    return this.isUploading;
  }

  public getError(): Error | void {
    return this.error;
  }
}

export const fileUploader = FileUploader.getInstance();
