import { Typography } from 'antd';
import { messages } from '../messages';
import { ScrolledItemWrapper } from '../../../components/ScrolledItemWrapper';

interface TransitionNotificationProps {
  message?: string;
}

export const TransitionNotification = ({
  message = messages.selectDownArrow,
}: TransitionNotificationProps) => (
  <ScrolledItemWrapper
    shouldBeScrolled
    className="animate-fade animate-once animate-duration-[3000ms] animate-ease-linear"
  >
    <Typography.Text className="italic text-sm laptop:text-base desktop:text-xl">
      {message}
    </Typography.Text>
  </ScrolledItemWrapper>
);
