import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Typography } from 'antd';
import { ContentEntity } from 'api/learning-steps/types';
import { MarkdownTypeAnimation } from 'components/MardownTypeAnimation';
import { TypeAnimation } from 'components/TypeAnimation';
import { ContentLayout } from '../layouts/ContentLayout';
import { messages } from '../messages';

interface CompleteCoursePreviewProps extends Partial<ContentEntity> {
  onAnimationDone: Dispatch<SetStateAction<boolean>>;
  showTransitionNotification?: boolean;
  modules?: string[];
}

export const CompleteCoursePreview = ({
  description,
  onAnimationDone,
  showTransitionNotification = false,
  modules = [],
}: CompleteCoursePreviewProps) => {
  const [isTypingDescriptionDone, setIsTypingDescriptionDone] =
    useState(!description);
  const [isTypingModulesDone, setIsTypingModulesDone] = useState(
    !modules?.length
  );

  useEffect(() => {
    if (isTypingModulesDone && isTypingDescriptionDone) {
      onAnimationDone(true);
    }

    return () => onAnimationDone(false);
  }, [isTypingModulesDone, isTypingDescriptionDone]);

  const modulesClassName = isTypingDescriptionDone ? 'visible' : 'invisible';

  return (
    <ContentLayout
      isCentralized
      showTransitionNotification={showTransitionNotification}
      transitionMessage={messages.closeLearningExperience}
    >
      {description && (
        <Typography.Paragraph className="text-sm laptop:text-base desktop:text-xl font-gelasio">
          <MarkdownTypeAnimation
            markdown={description}
            onTypingDone={() => setIsTypingDescriptionDone(true)}
          />
        </Typography.Paragraph>
      )}
      {modules?.length && (
        <Typography.Paragraph
          className={classNames(
            'text-sm laptop:text-base desktop:text-xl font-gelasio',
            modulesClassName
          )}
        >
          <TypeAnimation
            key={modulesClassName}
            onTypingDone={() => setIsTypingModulesDone(true)}
          >
            {messages.youLearnedWithUs}
            <div className="mx-4 my-2">
              <ul>
                {modules.map((name) => (
                  <li>{name}</li>
                ))}
              </ul>
            </div>
          </TypeAnimation>
        </Typography.Paragraph>
      )}
    </ContentLayout>
  );
};
