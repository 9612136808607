import { useCallback } from 'react';
import { fillUploadUrl } from '@tml-component-library/src/utils';
import { useGetFilesUploadUrlMutation } from 'api/file/file.api';
import { fileUploader } from 'utils';
import { UPLOAD_FILE_REQUEST_CONFIG } from 'shared';

export const useUploadFileByUrl = () => {
  const [getFilesUploadUrl, { isLoading }] = useGetFilesUploadUrlMutation();

  const uploadFileByUrl = useCallback(
    async (uuid: string, file: File) => {
      const { uploadFilesUrl } = await getFilesUploadUrl().unwrap();

      await fileUploader.uploadFile(
        fillUploadUrl(uploadFilesUrl, uuid, file.name),
        file,
        {
          headers: UPLOAD_FILE_REQUEST_CONFIG.headers(file.type),
        }
      );
    },
    [getFilesUploadUrl]
  );

  return {
    uploadFileByUrl,
    isLoading,
  };
};
