import { Divider } from 'antd';
import { useUserAssignedKbListQuery } from 'api/user/user.api';
import { LearningStatus } from 'api/user/types';
import { useUser } from 'common/user/useUser';
import { messages } from './messages';
import { CoursesSection } from './components/CoursesSection';

export const CoursesGrid = () => {
  const { data } = useUser();
  const { data: assignedKbList = [], isLoading } = useUserAssignedKbListQuery(
    data!.id
  );

  const currentCourses = assignedKbList?.filter(
    ({ learningStatus: status }) =>
      status === LearningStatus.NOT_STARTED ||
      status === LearningStatus.IN_PROGRESS
  );

  const completedCourses = assignedKbList?.filter(
    ({ learningStatus: status }) => status === LearningStatus.FINISHED
  );

  return (
    <div className="h-full flex flex-col gap-4">
      <CoursesSection
        isLoading={isLoading}
        courses={currentCourses}
        title={messages.currentCourses}
      />

      <Divider style={{ margin: 0 }} />

      <CoursesSection
        isLoading={isLoading}
        courses={completedCourses}
        title={messages.pastCourses}
      />
    </div>
  );
};
