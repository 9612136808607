import { Typography } from 'antd';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CheckboxButtonList } from '@tml-component-library/src';
import { MarkdownTypeAnimation } from 'components/MardownTypeAnimation';
import { OptionsEntity } from 'api/learning-steps/types';
import { ContentLayout } from '../layouts/ContentLayout';

interface MultipleQuestionProps {
  onAnswer: Dispatch<SetStateAction<string>>;
  onAnimationDone: Dispatch<SetStateAction<boolean>>;
  showResults: boolean;
  feedback?: string;
  correctValues?: string[];
  text?: string;
  options?: OptionsEntity;
  disabled?: boolean;
  showTransitionNotification?: boolean;
}

export const MultipleQuestion = ({
  text,
  onAnswer,
  showResults,
  onAnimationDone,
  feedback,
  correctValues,
  options = {},
  disabled,
  showTransitionNotification,
}: MultipleQuestionProps) => {
  const [isTypingDone, setIsTypingDone] = useState(!text);

  useEffect(() => {
    if (isTypingDone) {
      onAnimationDone(true);
    }

    return () => onAnimationDone(false);
  }, [isTypingDone]);

  const preparedOptions = Object.keys(options).map((key) => {
    return {
      buttonText: `${key.toUpperCase()}: ${options[key]}`,
      value: key.toUpperCase(),
      disabled,
    };
  });

  const handleChange = (selected: string[]) => {
    onAnswer(selected.join(','));
  };

  return (
    <ContentLayout
      isCentralized
      contentClassName="flex flex-col mb-4 gap-4"
      showTransitionNotification={showTransitionNotification}
    >
      {text && (
        <Typography.Paragraph className="text-sm laptop:text-base desktop:text-xl font-gelasio">
          <MarkdownTypeAnimation
            markdown={text}
            onTypingDone={() => {
              setIsTypingDone(true);
              onAnimationDone(true);
            }}
          />
        </Typography.Paragraph>
      )}
      {isTypingDone && (
        <CheckboxButtonList
          multiple
          showResults={showResults}
          buttonsProps={preparedOptions}
          correctValues={correctValues || []}
          onChange={handleChange}
        />
      )}
      {feedback && (
        <Typography.Paragraph className="text-sm laptop:text-base desktop:text-xl font-gelasio">
          <MarkdownTypeAnimation
            markdown={feedback}
            onTypingDone={() => onAnimationDone(true)}
            onTypingStarted={() => onAnimationDone(false)}
          />
        </Typography.Paragraph>
      )}
    </ContentLayout>
  );
};
