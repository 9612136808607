import { Navigate, NavLink, useLocation } from 'react-router-dom';
import { Typography } from 'antd';
import { Routes } from 'router/routes';
import { messages } from './messages';

export const SignInError = () => {
  const { state } = useLocation();

  const error = state?.error;

  if (!error)
    return (
      <Navigate
        to={Routes.signIn()}
        replace
      />
    );

  return (
    <div className="max-w-64 flex flex-col text-center">
      <Typography.Title level={5}>
        {messages.loginLinkExpiredOrInvalid}
      </Typography.Title>
      <Typography.Text className="mb-3 text-base font-normal">
        {messages.tryToLogin}
      </Typography.Text>
      <NavLink to={Routes.signIn()}>
        <Typography.Text
          className="mb-8 text-xs cursor-pointer"
          underline
        >
          {messages.returnToLogin}
        </Typography.Text>
      </NavLink>
    </div>
  );
};
